import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import config from '../config';

const InterestsDropDown = (props: any) => {
    const [interests, setInterests] = useState([])

    useEffect(() => {
        const fetchInterests = async () => {
            const response = await fetch(`${config.baseUrl}/interests`);
            const interestsData = await response.json();

            const options = interestsData.map((d: any) => ({
                "value": d.id,
                "label": d.description
            }))

            setInterests(options)
        }

        fetchInterests()
    }, [])

    const handleChange = (event: any) => {
        props.onchange(event);
    }

    return (
        <Select options={interests} onChange={handleChange} isMulti />
    );
};

export default InterestsDropDown;