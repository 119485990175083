import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { useApiRequest } from "../../../useApiRequest";
import NavBar from "../../Navbar/Navbar";
import styles from "./Payments.module.css";
import formTitle from '../../../images/payments_form_title.png'
import Pagination from '../../Pagination/Pagination';
import config from '../../../config';
import swal from 'sweetalert';
import axios from 'axios';

let PageSize = 10;

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Payments = () => {
    // call your hook here
    const forceUpdate = useForceUpdate();

    const [currentPage, setCurrentPage] = useState(1);
    const { data, loading, error } = useApiRequest('/payments');

    console.log(data)

    const handleClick = (url) => {
        window.open(url);
      };

    const consultarAprobar = (id) => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez aprobado, no se podrá revertir!",
            icon: "warning",
            buttons: ["Cancelar", "Aprobar"],
            dangerMode: true,
          })
          .then((willApprove) => {
            if (willApprove) {
                aprobar(id)
            }
          });
    }

    const aprobar = (id) => {
        axios.put(`${config.baseUrl}/payments/approve/${id}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) {
                  swal("Se ha aprobado el pago!", {icon: "success",}).then((value)=>{
                    window.location.reload(false);
                  });
            }
        }).catch(error => {
            swal(error.response.data.message);
            console.log(error.response.data)
        });
    }

    const consultarRechazar = (id) => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez rechazado, no se podrá revertir!",
            icon: "warning",
            buttons: ["Cancelar", "Rechazar"],
            dangerMode: true,
          })
          .then((willReject) => {
            if (willReject) {
                rechazar(id)
            }
          });
    }

    const rechazar = (id) => {
        axios.put(`${config.baseUrl}/payments/reject/${id}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) {
                  swal("Se ha rechazado el pago!", {icon: "success",}).then((value)=>{
                    window.location.reload(false);
                  });
            }
        }).catch(error => {
            swal(error.response.data.message);
            console.log(error.response.data)
        });
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.data ? data.data.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, data]);

    return (
        <div className={styles.my_dashboard_page}>
            <NavBar />

            <div className="card" style={{backgroundColor:"transparent", border:0}}>
                <img id="titleImg" src={formTitle} style={{width:400, marginLeft:"8%"}}/>
                <div className={`card-body ${styles.rounded}`}>
                    <div className={`${styles.dashboard_table_container}`}>
                        <table className="table" style={{borderCollapse:"collapse", borderRadius:"1.50em", overflow:"hidden", marginBottom:"-3px", border:"white"}}>
                            <thead className={`${styles.dashboard_table_header}`}>
                                <tr>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Articulo</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Comprobante</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (data.data && data.data.length > 0) ?
                                        currentTableData.map(d => (
                                            <tr key={d.id}>
                                                <td><Link to={`/profiles/${d.bid.user.id}`} className={`${styles.chip_generic}`}>{d.bid.user.name} {d.bid.user.lastname}</Link> </td>
                                                <td><Link to={`/profiles/${d.bid.user.id}`} className={`${styles.chip_generic}`}>{d.bid.user.email}</Link> </td>
                                                <td><Link to={`/auctions/${d.bid.product.id}`} className={`${styles.chip_generic}`}>{d.bid.product.name}</Link> </td>
                                                <td>{d.total_amount}</td>
                                                <td><img height={48} src={d.picture} onClick={()=>handleClick(d.picture)} style={{cursor: 'pointer'}}/></td>
                                                <td>{d.created_at}</td>
                                                <td>
                                                    {/* rechazados */}
                                                    {
                                                        d.is_cancelled === 1 ? <span className={`${styles.chip_inactive}`}>Rechazado</span> : ''
                                                    }

                                                    {/* pendientes */}
                                                    {
                                                        d.is_approved === 0 && d.is_cancelled !== 1 ? <span className={`${styles.chip_pending}`}>Pendiente</span> : ''
                                                    }

                                                    {/* aprobados */}
                                                    {
                                                        d.is_approved === 1 && d.is_cancelled !== 1 ? <span className={`${styles.chip_sold}`}>Aprobado</span> : ''
                                                    }                                               
                                                </td>
                                                <td>
                                                    {/* Aprobar */}
                                                    {
                                                        d.is_approved === 0 && d.is_cancelled !== 1 ? <button type="button" className={styles.chip_sold} onClick={()=>consultarAprobar(d.id)}>Aprobar</button> : ''
                                                    }

                                                    {/* Rechazar */}
                                                    {
                                                        d.is_cancelled !== 1 ? <button type="button" className={styles.chip_inactive} onClick={()=>consultarRechazar(d.id)}>Rechazar</button> : ''
                                                    }                                               
                                                </td>
                                            </tr>
                                        ))
                                    : <tr>
                                    <td>No hay datos</td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                </tr>
                                }
                            </tbody>
                        </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.data ? data.data.length : 0}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Payments;