import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { useApiRequest } from "../../../useApiRequest";
import NavBar from "../../Navbar/Navbar";
import styles from "./Questions.module.css";
import formTitle from '../../../images/questions_form_title.png'
import Pagination from '../../Pagination/Pagination';
import config from '../../../config';
import swal from 'sweetalert';
import axios from 'axios';

let PageSize = 10;

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Questions = () => {
    // call your hook here
    const forceUpdate = useForceUpdate();

    const [currentPage, setCurrentPage] = useState(1);
    const { data, loading, error } = useApiRequest('/knowledgebase');

    console.log(data)

    const handleClick = (url) => {
        window.open(url);
      };

    const consultarEliminar = (id) => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez eliminado, no se podrá revertir!",
            icon: "warning",
            buttons: ["Cancelar", "Borrar"],
            dangerMode: true,
          })
          .then((willReject) => {
            if (willReject) {
                eliminar(id)
            }
          });
    }

    const eliminar = (id) => {
        axios.delete(`${config.baseUrl}/knowledgebase/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: null
          }).then(response => {
            if (response.data.success === true) {
                  swal("Se ha eliminado el registro!", {icon: "success",}).then((value)=>{
                    window.location.reload(false);
                  });
            }
        }).catch(error => {
            console.log(localStorage.getItem("token"));
            console.log(error.response.data)
            console.log(error)
            swal(error.response.data.message);
        });
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.data ? data.data.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, data]);

    return (
        <div className={styles.my_dashboard_page}>
            <NavBar />

            <div className="card" style={{backgroundColor:"transparent", border:0}}>
                <img id="titleImg" src={formTitle} style={{width:400, marginLeft:"8%"}}/>
                <div className={`card-body ${styles.rounded}`}>
                    <p>Si desea crear un nuevo registro <Link to={`/createQuestion`} className={`${styles.chip_active}`}>Haga click aqui</Link></p>

                    <div className={`${styles.dashboard_table_container}`}>
                        <table className="table" style={{borderCollapse:"collapse", borderRadius:"1.50em", overflow:"hidden", marginBottom:"-3px", border:"white"}}>
                            <thead className={`${styles.dashboard_table_header}`}>
                                <tr>
                                    <th scope="col">Pregunta</th>
                                    <th scope="col">Respuesta</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (data.data && data.data.length > 0) ?
                                        currentTableData.map(d => (
                                            <tr key={d.id}>
                                                <td><Link to={`/questions/${d.id}`} className={`${styles.chip_generic}`}>{d.question}</Link> </td>
                                                <td>{d.answer}</td>
                                                <td>
                                                    <Link to={`/questions/${d.id}`} className={`${styles.chip_active}`}>Editar</Link>
                                                    <button type="button" className={styles.chip_inactive} onClick={()=>consultarEliminar(d.id)}>Borrar</button>
                                                </td>                                        
                                            </tr>
                                        ))
                                    : <tr>
                                    <td>No hay datos</td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                </tr>
                                }
                            </tbody>
                        </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.data ? data.data.length : 0}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Questions;