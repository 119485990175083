import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import NavBar from "../../Navbar/Navbar";
import UserListComponent from "../UserListComponent/UserListComponent";
import formTitle from "../../../images/profile_users_form_title.png";
import formUserTypesTitle from "../../../images/profile_user_type_form_title.png";
import { useApiRequest } from "../../../useApiRequest";
import styles from "./Profiles.module.css";
import CountriesDropDown from "../../CountriesDropDown";
import axios from 'axios';
import config from '../../../config';
import swal from 'sweetalert';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

const Profiles = () => {
    const [isActive, setIsActive] = useState(false);
    const [selected, setSelected] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState();
    const [selectedUser, setSelectedUser] = useState(0);

    const [country_id, setCountry_id] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    const onchangeCountrySelect = (item) => {
        setCountry_id(item);
        setSelectedCountry(item.value);
    };

    const selectUser = (index) => {
        setSelectedUser(index)
    };

    const { data, isLoaded, error } = useApiRequest(`/show_user/${selectedUser}`);
    console.log(data)
    
    useMemo(() => {
        setIsActive(data.data ? (data.data.state === 1) : false)
    }, [data]);

    const onSubmit = (data, e) => {
        var users = [];
        if (selected.length == 0) users.push(0)
        else {
            selected.forEach(element => users.push(element.value));
        }

        data.user_id = users.join();

        setMessage({
            data: "Enviando mensaje...",
            type: "alert-warning",
        });

        fetch('/notifications', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                const hasError = !data.success;

                setMessage({
                    data: hasError ? "Mensaje no enviado" : "Mensaje enviado...",
                    type: hasError ? "alert-danger" : "alert-success",
                });
                !hasError && e.target.reset();
            });
    };

    function toggleState() {
        let action = isActive ? 'inactivate' : 'activate'

        axios.put(`${config.baseUrl}/users/${action}/${selectedUser}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) 
                setIsActive(!isActive)
        });
    }

    const consultarEliminar = () => {
        swal({
            title: "¿Está seguro?",
            text: "Una vez eliminado, no se podrá revertir!",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                eliminar()
            }
          });
    }

    const eliminar = () => {
        console.log(selectedUser)

        axios.put(`${config.baseUrl}/users/delete/${selectedUser}`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            if (response.data.success === true) {
                  swal("Se ha eliminado el usuario!", {icon: "success",}).then((value)=>{
                    window.location.reload(false);
                  });
            }
        }).catch(error => {
            swal(error.response.data.message);
            console.log(error.response.data)
        });
    }

    return (
        <div className={styles.my_notifications_page}>
            <NavBar />
            <div className="row" style={{ marginTop: "80px", width: "100%" }}>
                <div className="col-md-6">
                    <div className="card" style={{ backgroundColor: "transparent", border: 0 }}>
                        <img id="titleImg" alt="" src={formTitle} style={{ width: 400, marginLeft: "16%" }} />
                        <div className={`card-body ${styles.rounded}`}>                        
                            {
                                !isLoaded ? <div className={styles.loader}></div> :
                                    <div className={styles.loginFormContainer}>
                                        {message && (
                                            <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                                {message.data}
                                                <span
                                                    aria-hidden="true"
                                                    className="ml-auto cursor-pointer"
                                                    onClick={() => setMessage(null)}>
                                                    &times;
                                                </span>
                                            </div>
                                        )}
                                        <fieldset className="p-3 rounded">
                                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                                <div className="row">
                                                    <div className="form-group">
                                                        <input
                                                            id="inputForName"
                                                            name="name"
                                                            type="text"
                                                            value={data.data ? data.data.name : ''}
                                                            className={`form-control ${styles.form_control_rounded}`}
                                                            aria-describedby="Nombre y apellido"
                                                            placeholder="Nombre y apellido" readOnly />
                                                        {errors.email && (
                                                            <span className={`${styles.errorMessage} mandatory`}>
                                                                {errors.email.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: "40px" }}>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                id="inputForDni"
                                                                name="dni"
                                                                type="text"
                                                                className={`form-control ${styles.form_control_rounded}`}
                                                                value={data.data ? (data.data.personal_id ?? '') : ''}
                                                                aria-describedby="Ingresa la cédula"
                                                                placeholder="Cédula" readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                id="inputForInterests"
                                                                name="interests"
                                                                type="text"
                                                                className={`form-control ${styles.form_control_interests_rounded}`}
                                                                value={data.data ? data.data.interests.map(d => d.description).join(',') : ''}
                                                                aria-describedby="Ingresa los intereses"
                                                                placeholder="Intereses" readOnly />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: "40px" }}>
                                                    <div className="form-group">
                                                        <input
                                                            id="inputForEmail"
                                                            name="email"
                                                            type="email"
                                                            className={`form-control ${styles.form_control_email_rounded}`}
                                                            aria-describedby="Enter email address"
                                                            placeholder="Correo"
                                                            value={data.data ? data.data.email : ''} readOnly />
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: "40px" }}>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                id="inputForPhone"
                                                                name="phone"
                                                                type="phone"
                                                                className={`form-control ${styles.form_control_phone_rounded}`}
                                                                aria-describedby="Ingresa el teléfono"
                                                                value={data.data ? data.data.phone : ''}
                                                                placeholder="Número de Teléfono" readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <CountriesDropDown name="country"
                                                                value={data.data ? data.data.country_id : 0} onchange={(e) => { onchangeCountrySelect(e) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: "30px", width: "100%" }}>
                                                    <div className="col-md-4">
                                                        {
                                                            (data.data != null && data.data.email == "admin@subastaapp.com") ? <Link to={`/change-password`} className={`${styles.chip_active}`}>Cambiar Contraseña</Link> : ''
                                                        }                                                        
                                                    </div>

                                                    {/* Boton eliminar usuario */}
                                                    <div className="col-md-4">
                                                        {(data.data != null && data.data.email != "admin@subastaapp.com") ? <button type="button" className="btn btn-danger" onClick={consultarEliminar}>Borrar</button> : ''}
                                                    </div>

                                                    {
                                                        isActive ? 
                                                            <div className="col-md-3">
                                                                <button type="button" className={styles.suspend_button} onClick={toggleState}></button>
                                                            </div> : 
                                                            <div className="col-md-3">
                                                                <button type="button" className={styles.activate_button} onClick={toggleState}></button>
                                                            </div>
                                                    }
                                                    
                                                </div>
                                            </form>
                                        </fieldset>

                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-md-2"></div>

                <div className="col-md-4">
                    <div className="card" style={{ backgroundColor: "transparent", border: 0 }}>
                        <img id="titleImg" alt="" src={formUserTypesTitle} style={{ width: 400, marginLeft: "16%" }} />
                        <div className={`card-body ${styles.rounded}`}>
                            <div className={styles.loginFormContainer}>
                                {message && (
                                    <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                        {message.data}
                                        <span
                                            aria-hidden="true"
                                            className="ml-auto cursor-pointer"
                                            onClick={() => setMessage(null)}>
                                            &times;
                                        </span>
                                    </div>
                                )}
                                <fieldset className="p-3 rounded">
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                        <UserListComponent sendDataToParent={selectUser} />
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profiles;
