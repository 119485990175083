import React from "react"; import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
} from "react-router-dom";
import Product from "./components/Auctions/Details/Product";
import Auctions from "./components/Auctions/Index/Auctions";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Notifications from "./components/Notifications/Notifications";
import ChangePassword from "./components/Passwords/ChangePassword";
import Payments from "./components/Payments/Index/Payments";
import User from "./components/Profiles/Detail/User";
import Profiles from "./components/Profiles/Index/Profiles";
import QuestionCreate from "./components/Questions/Create/QuestionCreate";
import Question from "./components/Questions/Details/Question";
import Questions from "./components/Questions/Index/Questions";
import Register from "./components/Register/Register";

const authGuard = (Component) => () => {
    return localStorage.getItem("token") ? (
        <Component />
    ) : (
        <Redirect to="/login" />
    );
};

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/register">
                <Register />
            </Route>
            <Route path="/auctions/:id" render={authGuard(Product)}></Route>
            <Route path="/auctions" render={authGuard(Auctions)}></Route>
            <Route path="/payments" render={authGuard(Payments)}></Route>
            <Route path="/change-password" render={authGuard(ChangePassword)}></Route>
            <Route path="/notifications" render={authGuard(Notifications)}></Route>
            <Route path="/profiles/:id" render={authGuard(User)}></Route>
            <Route path="/profiles" render={authGuard(Profiles)}></Route>
            <Route path="/questions/:id" render={authGuard(Question)}></Route>
            <Route path="/createQuestion" render={authGuard(QuestionCreate)}></Route>
            <Route path="/questions" render={authGuard(Questions)}></Route>
            <Route exact path="/">
                <Redirect to="/auctions" />
            </Route>
            <Route path="*">
                <NotFound />
            </Route>
        </Switch>
    </Router>
);

export default Routes;