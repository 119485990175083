import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import config from "../../config";
import NavBar from "../Navbar/Navbar";
import formTitle from "../../images/change_password_form_title.png";
import { MultiSelect } from "react-multi-select-component";
import styles from "./ChangePassword.module.css";
import swal from 'sweetalert';

const ChangePassword = () => {
    const [selected, setSelected] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const {register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState();
    const history = useHistory();
    
    const logout = async () => {
        localStorage.removeItem('token');
            history.push('/login');
            window.location.reload();
    }

    const onSubmit = (data, e) => {
        console.log(data);
        
        setMessage({
            data: "Cambiando la contraseña...",
            type: "alert-warning",
        });
        
        fetch(`${config.baseUrl}/change-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            const hasError = !data.success;

            setMessage({
                data: hasError ? "No se ha cambiado la contraseña" : "Se ha cambiado la contraseña...",
                type: hasError ? "alert-danger" : "alert-success",
            });
            !hasError && e.target.reset();

            if(hasError) {
                swal(data.message);
            } else {
                swal("Se ha cambiado la contraseña")
                .then((value) => {
                    logout();
                });
            }
        });
    };

  return (
    <div className={styles.my_notifications_page}>
      <NavBar />
      <div className="row" style={{marginTop:"80px", width:"100%"}}>
        <div className="col-md-6">

        <div className="card" style={{backgroundColor:"transparent", border:0}}>
                <img id="titleImg" alt="" src={formTitle} style={{width:400, marginLeft:"16%"}}/>
                <div className={`card-body ${styles.rounded}`}>
                    <div className={styles.loginFormContainer}>
                            {message && (
                                <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                    {message.data}
                                    <span
                                        aria-hidden="true"
                                        className="ml-auto cursor-pointer"
                                        onClick={() => setMessage(null)}>
                                        &times;
                                    </span>
                                </div>
                            )}
                            <fieldset className="p-3 rounded">
                                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">                                   

                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="current_password"
                                            rows="10"
                                            maxLength="140"
                                            className={`form-control ${styles.form_control_rounded}`}
                                            id="inputForMessage"
                                            placeholder="Escribe tu contraseña actual"
                                            {
                                            ...register('current_password', {
                                                required: {
                                                    value: true,
                                                    message: "Por favor, ingrese la contraseña actual",
                                                }
                                            })
                                            } />
                                        {errors.message && (
                                            <span className={`${styles.errorMessage} mandatory`}>
                                                {errors.message.message}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                    <br/>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="new_password"
                                            rows="10"
                                            maxLength="140"
                                            className={`form-control ${styles.form_control_rounded}`}
                                            id="inputForMessage"
                                            placeholder="Escribe tu nueva contraseña"
                                            {
                                            ...register('new_password', {
                                                required: {
                                                    value: true,
                                                    message: "Por favor, ingrese la nueva contraseña",
                                                }
                                            })
                                            } />
                                        {errors.message && (
                                            <span className={`${styles.errorMessage} mandatory`}>
                                                {errors.message.message}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                    <br/>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="new_confirm_password"
                                            rows="10"
                                            maxLength="140"
                                            className={`form-control ${styles.form_control_rounded}`}
                                            id="inputForMessage"
                                            placeholder="Escribe otra vez tu nueva contraseña"
                                            {
                                            ...register('new_confirm_password', {
                                                required: {
                                                    value: true,
                                                    message: "Por favor, ingrese la contraseña actual",
                                                }
                                            })
                                            } />
                                        {errors.message && (
                                            <span className={`${styles.errorMessage} mandatory`}>
                                                {errors.message.message}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <br/>
                                        <br/>                                
                                    </div>

                                    <div>
                                        <button type="submit" className={styles.btn_grad} style={{position:"absolute", right:"7%", bottom:"1%"}}>
                                            Cambiar Contraseña
                                        </button>
                                    </div>
                                </form>
                            </fieldset>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
