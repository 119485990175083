import { useEffect } from "react";
import styles from "./User.module.css";
import NavBar from "../../Navbar/Navbar";
import formTitle from "../../../images/customer_form_title.png";
import { useParams } from 'react-router-dom';
import {useApiRequest} from '../../../useApiRequest';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const User = (id) => {
    const params = useParams();
    const pictures = []
    const { data, loading, error } = useApiRequest(`/show_user/${params.id}`);

    console.log(data)

    useEffect(() => {
        if (data.data != null) {
            pictures.push({
                "original": data.data.avatar_file,
                "thumbnail": data.data.avatar_file
            });
        }
    }, [data, pictures]);

    return (
        <div className={styles.product_page}>
            <NavBar />
            <div className="row" style={{marginTop:"30px", width:"100%"}}>
                <div className="col-md-12">
                    <div className="card" style={{backgroundColor:"transparent", border:0}}>
                        <img id="titleImg" alt="" src={formTitle} style={{width:400, marginLeft:"16%"}}/>
                        <div className={`card-body ${styles.rounded}`}>
                            <div className={styles.loginFormContainer}>
                                <fieldset className="p-3 rounded">
                                    <form noValidate autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="name">Nombres</label>
                                                <input type="text" name="name" 
                                                    className={`form-control ${styles.form_control_rounded}`} 
                                                    value={data.data ? data.data.name : ''}
                                                    readOnly={true}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="lastname">Apellidos</label>
                                                <input type="text" name="lastname" 
                                                    className={`form-control ${styles.form_control_rounded}`} 
                                                    value={data.data ? data.data.lastname : ''}
                                                    readOnly={true}/>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label htmlFor="description">Email</label>
                                            <input type="text" name="description" 
                                                className={`form-control ${styles.form_control_rounded}`} 
                                                value={data.data ? data.data.email : ''}
                                                readOnly={true}/>
                                        </div>                                   
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="personal_id">DNI</label>
                                                    <input type="text" name="personal_id" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.personal_id : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="phone">Teléfono</label>
                                                    <input type="text" name="phone" 
                                                        className={`form-control ${styles.form_control_rounded}`} 
                                                        value={data.data ? data.data.phone : ''}
                                                        readOnly={true}/>
                                                </div>
                                            </div>
                                        </div>

                                        {data.data ? 

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Intereses</h4>
                                                <div>
                                                    {data.data.interests.map(i => (
                                                        <li key={i.id} className={styles.chip}>{i.description}</li>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> : <div></div> }

                                        <div className="row" style={{marginBottom:"40px", marginTop:"40px", width:"25%"}}>
                                            <ImageGallery items={pictures} />
                                        </div>
                                        
                                        <div>
                                            <button type="submit" className={styles.btn_grad} style={{position:"absolute", right:"7%", bottom:"1%"}}>
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default User;