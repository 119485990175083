import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import config from "../../config";
import NavBar from "../Navbar/Navbar";
import formTitle from "../../images/notification_form_title.png";
import { MultiSelect } from "react-multi-select-component";
import styles from "./Notifications.module.css";

const Notifications = () => {
    const [selected, setSelected] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const {register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState();
    const history = useHistory();
    
    const logout = () => {
        /* eslint-disable */
        const toLogout = confirm("Are you sure to logout ?");
        /* eslint-enable */
        if (toLogout) {
            localStorage.clear();
            history.push("/login");
        }
    };

    const getUsers = () => {
        fetch(`${config.baseUrl}/users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        .then((res) => res.json())
        .then((data) => {
            var optionsArray = [];

            data.data.map( ( {id, email} ) => {
                console.log(id + ", " + email);
                const obj = {label: email, value: id};
                optionsArray.push(obj);
            });

            setUsersArray(optionsArray);
        });
    }
    
    useEffect(() => {
        fetch(`${config.baseUrl}/get_user`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        .then((res) => res.json())
        .then((data) => {
            const hasError = !data.success;
            console.log(data);
            if(hasError) history.push("/login");
        });

        getUsers();
    }, [history]);
    
    const onSubmit = (data, e) => {
        var users = [];
        if (selected.length == 0) users.push(0)
        else {
            selected.forEach(element => users.push(element.value));
        }

        data.user_id = users.join();
        console.log(data);
        
        setMessage({
            data: "Enviando mensaje...",
            type: "alert-warning",
        });
        
        fetch(`${config.baseUrl}/notifications`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            const hasError = !data.success;

            setMessage({
                data: hasError ? "Mensaje no enviado" : "Mensaje enviado...",
                type: hasError ? "alert-danger" : "alert-success",
            });
            !hasError && e.target.reset();
        });
    };

    const customStrings = {
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Buscar",
        "selectAll": "Todos",
        "selectSomeItems": "Seleccionar destinatario..."
    };

  return (
    <div className={styles.my_notifications_page}>
      <NavBar />
      <div className="row" style={{marginTop:"80px", width:"100%"}}>
        <div className="col-md-6">

        <div className="card" style={{backgroundColor:"transparent", border:0}}>
                <img id="titleImg" alt="" src={formTitle} style={{width:400, marginLeft:"16%"}}/>
                <div className={`card-body ${styles.rounded}`}>
                <div className={styles.loginFormContainer}>
                        {message && (
                            <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                {message.data}
                                <span
                                    aria-hidden="true"
                                    className="ml-auto cursor-pointer"
                                    onClick={() => setMessage(null)}>
                                    &times;
                                </span>
                            </div>
                        )}
                        <fieldset className="p-3 rounded">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="form-group">
                                    <MultiSelect
                                    className={styles.multi_select}
                                        options={usersArray}
                                        value={selected}
                                        style={styles.rmsc}
                                        onChange={setSelected}
                                        overridingStrings={customStrings}
                                        labelledBy="Seleccione un destinatario" />
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div className="form-group">
                                    <textarea
                                        type="text"
                                        name="message"
                                        rows="10"
                                        maxLength="140"
                                        className={`form-control ${styles.form_control_rounded}`}
                                        id="inputForMessage"
                                        placeholder="Escribe un mensaje"
                                        {
                                        ...register('message', {
                                            required: {
                                                value: true,
                                                message: "Por favor, ingrese el texto del mensaje",
                                            },
                                            maxLength: {
                                                value: 140,
                                                message: "El máximo de 140 caracteres se ha excedido",
                                            },
                                        })
                                        } />
                                    {errors.message && (
                                        <span className={`${styles.errorMessage} mandatory`}>
                                            {errors.message.message}
                                        </span>
                                    )}
                                </div>

                                <div style={{padding:"5px"}}>
                                    <span>Máximo de 140 caracteres</span>
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div>
                                    <button type="submit" className={styles.btn_grad} style={{position:"absolute", right:"7%", bottom:"1%"}}>
                                        Enviar
                                    </button>

                                    {/* <button type="submit" className="btn btn-secondary pull-right">Button</button> */}
                                </div>
                            </form>
                        </fieldset>
                    </div>
                    </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
