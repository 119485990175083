import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { useApiRequest } from "../../../useApiRequest";
import NavBar from "../../Navbar/Navbar";
import styles from "./Auctions.module.css";
import formTitle from '../../../images/auctions_form_title.png'
import Pagination from '../../Pagination/Pagination';
import config from '../../../config';

let PageSize = 10;

const Auctions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { data, loading, error } = useApiRequest('/products/showAll');

    console.log(data)

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.data ? data.data.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, data]);

    return (
        <div className={styles.my_dashboard_page}>
            <NavBar />

            <div className="card" style={{backgroundColor:"transparent", border:0}}>
                <img id="titleImg" src={formTitle} style={{width:400, marginLeft:"8%"}}/>
                <div className={`card-body ${styles.rounded}`}>
                    <div className={`${styles.dashboard_table_container}`}>
                        <table className="table" style={{borderCollapse:"collapse", borderRadius:"1.50em", overflow:"hidden", marginBottom:"-3px", border:"white"}}>
                            <thead className={`${styles.dashboard_table_header}`}>
                                <tr>
                                    <th scope="col">Artículo</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Pujas</th>
                                    <th scope="col">Puja +Alta</th>
                                    <th scope="col">Pagado</th>
                                    <th scope="col">Comprador</th>
                                    <th scope="col">Inicio</th>
                                    <th scope="col">Fin</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (data.data && data.data.length > 0) ?
                                        currentTableData.map(d => (
                                            <tr key={d.id}>
                                                <td>
                                                    {/* activos */}
                                                    {
                                                        d.state === config.ACTIVO ? 
                                                            <Link to={`/auctions/${d.id}`} 
                                                                className={`${styles.chip_active}`}>
                                                                    {d.name.substring(0, 40) } { d.name.length >= 40 && `...` }
                                                            </Link> : ''
                                                    }

                                                    {/* inactivos */}
                                                    {
                                                        d.state === config.INACTIVO ? 
                                                            <Link to={`/auctions/${d.id}`} 
                                                                className={`${styles.chip_inactive}`}>
                                                                    {d.name.substring(0, 40) } { d.name.length >= 40 && `...` }
                                                            </Link> : ''
                                                    }

                                                    {/* vendidos */}
                                                    {
                                                        d.state === config.VENDIDO || d.state == config.ENTREGADO ? 
                                                            <Link to={`/auctions/${d.id}`} 
                                                                className={`${styles.chip_sold}`}>
                                                                    {d.name.substring(0, 40) } { d.name.length >= 40 && `...` }
                                                            </Link> : ''
                                                    }     

                                                    {/* expirado */}
                                                    {
                                                        d.state === config.EXPIRADO ? 
                                                            <Link to={`/auctions/${d.id}`} 
                                                                className={`${styles.chip_expired}`}>
                                                                    {d.name.substring(0, 40) } { d.name.length >= 40 && `...` }
                                                            </Link> : ''
                                                    }       

                                                    {/* rechazado */}
                                                    {
                                                        d.state === config.RECHAZADO ? 
                                                            <Link to={`/auctions/${d.id}`} 
                                                                className={`${styles.chip_inactive}`}>
                                                                    {d.name.substring(0, 40) } { d.name.length >= 40 && `...` }
                                                            </Link> : ''
                                                    }                                                    
                                                </td>

                                                <td>
                                                    {/* activos */}
                                                    {
                                                        d.state === config.ACTIVO ? 'Activo' : ''
                                                    }

                                                    {/* inactivos */}
                                                    {
                                                        d.state === config.INACTIVO ? 'Inactivo' : ''
                                                    }

                                                    {/* vendidos */}
                                                    {
                                                        d.state === config.VENDIDO ? 'Vendido' : ''
                                                    }     

                                                    {/* expirado */}
                                                    {
                                                        d.state === config.EXPIRADO ? 'Expirado' : ''
                                                    }

                                                    {/* entregado */}
                                                    {
                                                        d.state === config.ENTREGADO ? 'Entregado' : ''
                                                    }   

                                                    {/* rechazado */}
                                                    {
                                                        d.state === config.RECHAZADO ? <span className={`${styles.chip_user_inactive}`}>Rechazado</span> : ''
                                                    }                                                    
                                                </td>

                                                <td>
                                                    {/* activos */}
                                                    {
                                                        d.user.state === config.ACTIVO ? 
                                                            <Link to={`/profiles/${d.user.id}`} className={`${styles.chip_user_active}`}>{d.user.email}</Link> : ''
                                                    }

                                                     {/* inactivos */}
                                                     {
                                                        d.user.state !== config.ACTIVO ? 
                                                            <Link to={`/profiles/${d.user.id}`} className={`${styles.chip_user_inactive}`}>{d.user.email}</Link> : ''
                                                    }                                                    
                                                </td>
                                                <td>{d.base_price}</td>
                                                <td>{d.bids_count}</td>
                                                <td>{d.bid_amount}</td>
                                                <td>{d.is_payed ? <span className={`${styles.chip_user_active}`}>Si</span> : 
                                                    ( d.state !== config.VENDIDO && d.state !== config.ENTREGADO && d.state !== config.RECHAZADO ? '' : <span className={`${styles.chip_user_inactive}`}>No</span>)}</td>
                                                
                                                <td>{d.buyer != null ? d.buyer.name + ' ' + d.buyer.lastname : ''}</td>
                                                <td>{d.start_date}</td>
                                                <td>{d.end_date}</td>
                                                <td>
                                                    {
                                                        d.pictures != null && d.pictures.length > 0 ? <img height={48} src={d.pictures[0].picture}/> : ''
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    : <tr>
                                    <td>No hay datos</td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                    <td style={{borderLeft:"1px solid #000"}}></td>
                                </tr>
                                }
                            </tbody>
                        </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.data ? data.data.length : 0}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Auctions;