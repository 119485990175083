import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Login.module.css";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import config from "../../config";

import logo from '../../images/logo.png'
import formTitle from '../../images/login_form_title.png'

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState();
    const history = useHistory();

    const onSubmit = (data, e) => {
        console.log(data);

        setMessage({
            data: "Iniciando sesión...",
            type: "alert-warning",
        });
        fetch(`${config.baseUrl}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                const hasError = !data.success;

                setMessage({
                    data: hasError ? data.message : "Sesión iniciada correctamente, redirigiendo...",
                    type: hasError ? "alert-danger" : "alert-success",
                });

                !hasError && setTimeout(() => {
                    localStorage.setItem("token", data.token);
                    history.push("/auctions");
                }, 3000);
                !hasError && e.target.reset();
            });
    };

    return (
        <div className={styles.my_login_page}>
            <div>
                <img src={logo} alt="Logo" style={{width:400}}/>
            </div>

            <div style={{marginLeft:100, marginTop:120}}>
                <div className="card pull-left" id="form-container" style={{width:600, backgroundColor:"transparent", border:0}}>
                    <img id="titleImg" src={formTitle} style={{width:400, marginLeft:40}}/>
                    <div className="card-body" style={{backgroundColor:"white", borderRadius:50, alignContent:"center",width:"100%",position:"relative"}}>
                        <div className={styles.loginFormContainer}>
                        {message && (
                            <div className={`alert fade show d-flex ${message.type}`} role="alert">
                                {message.data}
                                <span
                                    aria-hidden="true"
                                    className="ml-auto cursor-pointer"
                                    onClick={() => setMessage(null)}>
                                    &times;
                                </span>
                            </div>
                        )}
                        <fieldset className="p-3 rounded">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="form-group">
                                    <input
                                        id="inputForEmail"
                                        name="email"
                                        type="email"
                                        className={`form-control ${styles.form_control_rounded}`}
                                        aria-describedby="Enter email address"
                                        placeholder="Correo"
                                        {
                                        ...register('email', {
                                            required: {
                                                value: true,
                                                message: "Please enter your email address",
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Enter a valid email address",
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "Minimum 6 characters are allowed",
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Maximum 255 characters are allowed",
                                            },
                                        })
                                        } />
                                    {errors.email && (
                                        <span className={`${styles.errorMessage} mandatory`}>
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        className={`form-control ${styles.form_control_rounded_password}`}
                                        id="inputForPassword"
                                        placeholder="Contraseña"
                                        {
                                        ...register('password', {
                                            required: {
                                                value: true,
                                                message: "Please enter password",
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "Minimum 6 characters are allowed",
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Maximum 255 characters are allowed",
                                            },
                                        })
                                        } />
                                    {errors.password && (
                                        <span className={`${styles.errorMessage} mandatory`}>
                                            {errors.password.message}
                                        </span>
                                    )}
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div>
                                   <br/>
                                </div>

                                <div className="d-flex align-items-center">
                                    <button type="submit" className={styles.btn_grad} style={{margin:"0 auto", display:"block"}}>
                                        Entrar
                                    </button>

                                    {/* <button className="btn btn-link ml-auto">
                                        <Link to="/register">New User</Link>
                                    </button> */}
                                </div>
                            </form>
                        </fieldset>
                    </div>
                    </div>
                </div>
            </div>            
        </div>
    );
};

export default Login;